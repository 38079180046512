<template>
  <div>
    <div v-if="isLargeViewport" class="bottomInf">
      <div class="otherSite">
        <span class="custom" @click="clickOtherSite('网站地图')">网站地图</span>
        <el-divider class="divider" direction="vertical"></el-divider>
        <span class="custom" @click="clickOtherSite('网站地图')">网站地图</span>
        <el-divider class="divider" direction="vertical"></el-divider>
        <span class="custom" @click="clickOtherSite('联系我们')">联系我们</span>
        <el-divider class="divider" direction="vertical"></el-divider>
        <span class="custom" @click="clickOtherSite('好友留言')">好友留言</span>
      </div>
      <div class="viewCount">
        <div class="info">
          <span>网站浏览人数:</span>
          <div class="circles">
            <div class="circle" v-for="(item, index) in circleNum" :key="index">
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="FilingInfo">
        <div class="text">
          <div>Copyright © 2023 新疆春秋艺术博物馆版权所有</div>
          <div class="flex justify-center">
            <div class="flex items-center">
              <el-image class="mr-1" :src="require('../assets/image/police.png')" alt="" />
              新公网安备号
              <a href="http://www.beian.gov.cn" class="custom" target="_blank">65010402001849</a>
            </div>
            <div class="ml-2">
              <a class="custom" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
                >新ICP备2022000419号-4</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="address">
        <p>馆址：新疆乌鲁木齐新市区北京中路二宫体育馆院内春秋艺术博物馆</p>
      </div>
    </div>
    <div v-else class="footer">
      <div class="footer-othersite">
        <span>法律声明</span>
        <el-divider direction="vertical"></el-divider>
        <span>网站地图</span>
        <el-divider direction="vertical"></el-divider>
        <span>联系我们</span>
        <el-divider direction="vertical"></el-divider>
        <span>网友留言</span>
      </div>
      <div class="footer-filinginfo">
        <div class="footer-filinginfo-item">
          <span>Copyright © 2023 新疆春秋艺术博物馆版权所有</span>
        </div>
        <div class="footer-filinginfo-item">
          <span
            ><a class="custom" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
              >新ICP备2022000419号-4</a
            ></span
          >
        </div>
        <div class="footer-filinginfo-item">
          <span
            ><span><el-image :src="require('../assets/image/police.png')" alt="" /></span
            >新公网安备号<a href="http://www.beian.gov.cn" class="custom" target="_blank"
              >65010402001849</a
            >
          </span>
        </div>
      </div>
      <div class="footer-viewcount">
        <span>网站浏览人数:</span>
        <div class="footer-viewcount-item">
          <span v-for="(item, index) in countList" :key="index">{{ item }}</span>
        </div>
      </div>
      <div class="footer-address">
        <span>馆址：新疆乌鲁木齐新市区北京中路二宫体育馆院内春秋艺术博物馆</span>
      </div>
    </div>
  </div>
</template>

<script>
import { onResize } from '@/tools/viewport';
export default {
  name: 'BottomInf',

  components: {},
  data() {
    return {
      isLargeViewport: true, // 默认为大视口
      viewCount: -1, // 浏览人数
      circleNum: [], // circleNum.length == 浏览人数圆圈数量
      countList: [1, 2, 5, 8, 9, 1],
    };
  },
  created() {
    this.getViewCount();
  },
  computed: {
    showBox() {
      return window.innerWidth <= 1500;
    },
  },
  methods: {
    handleResize() {
      this.isLargeViewport = onResize(window.innerWidth, document.documentElement.clientWidth);
    },
    /**
     * 获取浏览人数
     */
    getViewCount() {
      this.viewCount = 12334486;

      // 获取完人数后
      this.renderCircle();
    },
    /**
     * 处理浏览人数的圆圈
     */
    renderCircle() {
      this.circleNum = Math.abs(this.viewCount).toString().split('');
    },
    /**
     * 点击底部其他网站的时候的事件
     */
    clickOtherSite(site) {
      this.$router.push('/leavemsg');
    },
  },
  mounted() {
    this.isLargeViewport = onResize(window.innerWidth, document.documentElement.clientWidth); // 根据初始视口宽度设置 isLargeViewport
    window.addEventListener('resize', this.handleResize);
    this.$nextTick(() => {
      this.$wow.init();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped lang="scss">
.custom:hover {
  color: #85703d;
}
.bottomInf {
  min-width: 118.9375rem;
  background-image: url('../assets/image/bg2.png');
  padding: 5rem 0;
  font-size: 0.875rem;

  .otherSite {
    text-align: center;
    font-family: SourceHanSansCN-Normal;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #ffffff;

    .divider {
      margin: 0 2rem;
    }

    span {
      cursor: pointer;
    }
  }

  .FilingInfo {
    margin-top: 2rem;
    text-align: center;

    .text {
      font-family: SourceHanSansCN-Normal;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #b4b3b8;
    }
  }

  .FilingInfo:hover {
    cursor: pointer;
  }

  .viewCount {
    margin-top: 2rem;

    .info {
      text-align: center;
      display: flex;
      justify-content: center;

      span {
        width: 7rem;
        line-height: 1.75rem;
        letter-spacing: 0rem;
        color: #b4b3b8;
      }

      .circles {
        display: flex;

        .circle {
          width: 1.25rem;
          height: 1.75rem;
          line-height: 1.75rem;
          background-color: rgba($color: #b29550, $alpha: 0.7);
          margin-left: 0.75rem;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            letter-spacing: 0rem;
            font-stretch: normal;
          }
        }
      }
    }
  }

  .address {
    text-align: center;
    margin-top: 2rem;

    p {
      height: 1rem;
      letter-spacing: 0rem;
      color: #b4b3b8;
    }
  }

  .address:hover {
    cursor: pointer;
  }
}
.footer {
  background-image: url('../assets/image/h5/bg8.png');
  color: #fff;
  padding: 3.125rem 0;

  .footer-othersite {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.3125rem;

    ::v-deep .el-divider--vertical {
      margin: 0 0.875rem;
    }
  }

  .footer-filinginfo {
    margin-bottom: 1.4375rem;

    .footer-filinginfo-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.875rem;
    }
  }

  .footer-viewcount {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.25rem;

    .footer-viewcount-item {
      display: flex;
      margin-left: 0.5625rem;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.4375rem;
        height: 1.4375rem;
        background-color: #b29550;
        border-radius: 50%;
        margin-right: 0.5625rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .footer-address {
    padding: 0 1.625rem;
    text-align: center;

    span {
      line-height: 1.875rem;
    }
  }
}
</style>
