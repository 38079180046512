<template>
    <div class="footer">
        <div class="footer-othersite">
            <span>法律声明</span>
            <el-divider direction="vertical"></el-divider>
            <span>网站地图</span>
            <el-divider direction="vertical"></el-divider>
            <span>联系我们</span>
            <el-divider direction="vertical"></el-divider>
            <span>网友留言</span>
        </div>
        <div class="footer-filinginfo">
            <div class="footer-filinginfo-item">
                <span>Copyright © 2023 新疆春秋艺术博物馆版权所有</span>
            </div>
            <div class="footer-filinginfo-item">
                <span>新ICP备2022000419号-4</span>
            </div>
            <div class="footer-filinginfo-item">
                <span>新公网安备号65010402001849</span>
            </div>
        </div>
        <div class="footer-viewcount">
            <span>网站浏览人数:</span>
            <div class="footer-viewcount-item">
                <span v-for="(item, index) in countList" :key="index">{{ item }}</span>
            </div>
        </div>
        <div class="footer-address">
            <span>馆址：新疆乌鲁木齐新市区北京中路二宫体育馆院内春秋艺术博物馆</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterH5',
    components: {},
    data() {
        return {
            countList: [1, 2, 5, 8, 9, 1]
        }
    },
    created() { },
    mounted() { },
    computed: {},
    watch: {},
    methods: {},
}
</script>

<style lang='scss' scoped>
.footer {
    background-image: url("../assets/image/bg5.png");
    color: #fff;
    padding: 3.125rem 0;
    font-size: .9375rem;

    .footer-othersite {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.3125rem;

        ::v-deep .el-divider--vertical {
            margin: 0 .875rem;
        }
    }

    .footer-filinginfo {
        margin-bottom: 1.4375rem;

        .footer-filinginfo-item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: .875rem;
        }
    }

    .footer-viewcount {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.25rem;

        .footer-viewcount-item {
            display: flex;
            margin-left: .5625rem;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.4375rem;
                height: 1.4375rem;
                background-color: #b29550;
                border-radius: 50%;
                margin-right: .5625rem;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .footer-address {
        padding: 0 1.625rem;
        text-align: center;

        span {
            line-height: 1.875rem;
        }
    }
}
</style>