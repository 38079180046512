<template>
  <div>
    <div
      v-if="isLargeViewport"
      style="position: relative; min-width: 112.5rem; z-index: 99"
      @mouseleave="hideBox"
    >
      <div class="navbar">
        <div class="logo">
          <router-link to="/">
            <el-image
              v-if="$route.path === '/'"
              style="width: 100px; height: 71px"
              :src="require('../assets/image/logo.png')"
            ></el-image>
            <el-image
              v-else
              style="width: 100px; height: 71px"
              :src="require('@/assets/image/logo2.png')"
            ></el-image>
          </router-link>
        </div>
        <div>
          <el-menu
            class="el-menu-demo"
            mode="horizontal"
            route
            text-color="#fff"
            active-text-color="#c09b30"
            router
          >
            <el-menu-item
              class="navbar-menu"
              v-for="(item, index) in navBarList"
              :key="index"
              @mouseover.native="showBox(item, index)"
              @mouseleave.native="hideBox"
              :index="`/${item.attributes.route}`"
              @click="onChangeMenu(item.attributes, index)"
              :class="currentNav === item.attributes.route ? 'is-active' : ''"
            >
              {{ item.attributes.title }}
              <transition name="el-zoom-in-top">
                <div
                  v-if="currentNavHoverIndex === index"
                  @click.stop="onEmpty"
                  class="navbar-content-test"
                  :class="isBoxVisible ? 'navbar-content-show-test' : ''"
                >
                  <div style="color: #fff" :style="navbarContentStyle(index)">
                    <ul>
                      <li
                        class="navbar-content-item-test"
                        v-for="(it, i) in item.attributes.content"
                        :key="i"
                      >
                        <div
                          @click.stop="onGoAnchorKnow(it, i)"
                          class="navbar-content-item-container"
                        >
                          <span>{{ it.title }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </transition>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="tools">
          <button class="mbutton hoverEN">
            <el-image
              v-if="$route.path === '/'"
              :src="require('../assets/image/EN.png')"
            ></el-image>
            <el-image
              v-else
              :src="require('../assets/image/EN2.png')"
            ></el-image>
          </button>
          <button class="mbutton mx-4 hoverSearch" style="margin: 0 1rem">
            <el-image
              v-if="$route.path === '/'"
              :src="require('../assets/image/搜索 (3).png')"
            ></el-image>
            <el-image
              v-else
              :src="require('../assets/image/搜索.png')"
            ></el-image>
          </button>
          <button class="mbutton hoverList" @click="showModal">
            <el-image
              v-if="$route.path === '/'"
              :src="require('../assets/image/列表.png')"
            ></el-image>
            <el-image
              v-else
              :src="require('../assets/image/列表2.png')"
            ></el-image>
          </button>
          <transition-group name="el-fade-in">
            <div v-if="isModalVisible" class="modal-overlay" :key="1">
              <div class="modal-content">
                <!-- 这里关闭按钮 -->
                <button
                  style="cursor: pointer"
                  @click="closeModal"
                  class="icon-btn add-btn"
                >
                  <div class="add-icon"></div>
                  <div class="btn-txt">关闭</div>
                </button>
                <!-- 内容 -->
                <div class="mcontent">
                  <div
                    class="text-center"
                    v-for="(item, index) in navlist"
                    :key="index"
                  >
                    <div style="font-size: 28px; color: #fff">
                      {{ item.attributes.title }}
                    </div>
                    <div style="text-align: center; margin: 15px 0">
                      <el-image :src="require('../assets/image/ju1kao7.png')" />
                    </div>
                    <div
                      v-for="(i, index) in item.attributes.list"
                      :key="index"
                      @click="onGoAnchorKnow(i, index)"
                      class="mcontent-child wow backInDown"
                    >
                      {{ i.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
      <div class="bread" v-show="isShow">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <el-breadcrumb-item
            v-for="(item, index) in breadList"
            :key="index"
            :to="{ path: item.path }"
            >{{ item.title }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
    </div>
    <div v-else class="navbars">
      <div class="logos" @click="onGoToHomeH5">
        <el-image
          style="height: 37px"
          :src="require('../assets/image/h5/h5logob.png')"
        ></el-image>
      </div>
      <div class="toolss">
        <button class="mbuttons" @click="onShowDrawer">
          <el-image :src="require('../assets/image/h5/h5mw.png')"></el-image>
        </button>
      </div>
    </div>
    <NavbarDrawer
      v-if="drawer"
      @drawerController="onCloseDrawer"
      :navlist="navlist"
      :drawer="drawer"
    ></NavbarDrawer>
  </div>
</template>

<script>
import NavbarDrawer from "../components/NavbarDrawer.vue";
import { onGoAnchor } from "../tools/anchor";
import { onResize } from "@/tools/viewport";
import { getnavbar, getnavlist } from "../api/article";
export default {
  name: "Nav-bar",
  components: {
    NavbarDrawer,
  },
  data() {
    return {
      drawer: false,
      isLargeViewport: true, // 默认为大视口
      isModalVisible: false,
      activeIndex: "1",
      activeIndex2: "1",
      showContent: false,
      selectedContent: "",
      isBoxVisible: false, // 控制盒子的显示状态
      isShow: true, // 是否显示面包屑
      breadList: [], // 面包屑列表
      currentNavHoverIndex: -1, // 当前鼠标hover所在索引
      navBarList: [],
      navBarChild: [],
      navlist: [], //总览列表
    };
  },
  computed: {
    showBoxs() {
      return window.innerWidth <= 1500;
    },
    navbarContentStyle: function () {
      return function (index) {
        if (this.isBoxVisible) {
          return {
            "padding-left": `${430 + 135 * index}px`,
          };
        }
      };
    },
    currentNav() {
      return this.$store.state.currentNav;
    },
  },
  created() {},
  methods: {
    // 前往H5首页
    onGoToHomeH5() {
      this.$router.push("/h5");
    },
    onShowDrawer() {
      this.drawer = true;
    },
    onCloseDrawer(data) {
      this.drawer = data;
    },
    handleResize() {
      this.isLargeViewport = onResize(
        window.innerWidth,
        document.documentElement.clientWidth
      );
    },
    onEmpty() {},
    showModal() {
      if (this.$route.path === "/") {
        this.$emit("openModal"); // 触发自定义事件 openModal
      } else {
        this.isModalVisible = true;
      }
    },
    showModals() {
      this.$emit("openModal"); // 触发自定义事件 openModal
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showBox(data, index) {
      if (data.attributes.content.length !== 0) {
        this.isBoxVisible = true;
        this.navBarChild = data.attributes.content;
        this.currentNavHoverIndex = index;
      }
    },
    showBox1() {
      this.isBoxVisible = true;
    },
    hideBox() {
      this.isBoxVisible = false;
      this.currentNavHoverIndex = -1;
    },
    getBreadCrumb() {
      this.$route.matched.forEach((item) => {
        this.breadList.push({
          path: item.path,
          title: item.meta.title,
        });
      });
    },
    onChangeMenu(data, index) {
      if (data.route === "cultural") {
        this.$router.push(`/cultural/${1}`);
        this.$store.commit("timerLoading", false);
      }
      this.$store.commit("updateCurrentNav", data.route);
    },
    // 跳转到锚点
    onGoAnchorKnow(data) {
      if (data.route === "/cloud") {
        alert("asdasd")
      }
      this.closeModal();
      this.$store.commit("updateCurrentNav", data.route);
      if (data.anchor.split(0) !== "#") {
        this.$router.push(data.anchor);
        window.location.hash = "";
        getnavlist().then((res) => {
          this.navlist = res.data.data;
        });
      } else {
        onGoAnchor(data.name, data.anchor, this.$router);
      }
    },
    async getAllNavbar() {
      this.navBarList = [];
      const res = await getnavbar();
      res.data.data.forEach((item) => {
        if (item.attributes.route === null) {
          item.attributes.route = "";
        }
        item.attributes.id = item.id;
        this.navBarList.push(item);
      });
    },
  },
  mounted() {
    this.getAllNavbar();
    getnavlist().then((res) => {
      this.navlist = res.data.data;
    });
    this.getBreadCrumb();
    this.isShow = this.$route.meta.isShowBread;
    this.isLargeViewport = onResize(
      window.innerWidth,
      document.documentElement.clientWidth
    ); // 根据初始视口宽度设置 isLargeViewport
    window.addEventListener("resize", this.handleResize);
    if (
      this.$route.path.slice(1, this.$route.path.length).indexOf("/") !== -1
    ) {
      this.$store.commit(
        "updateCurrentNav",
        this.$route.path
          .slice(1)
          .slice(0, this.$route.path.slice(1).indexOf("/"))
      );
    } else {
      this.$store.commit(
        "updateCurrentNav",
        this.$route.path.slice(1, this.$route.path.length)
      );
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    $route(newVal) {
      this.isShow = newVal.meta.isShowBread;
      this.breadList = [];
      this.$route.matched.forEach((item) => {
        this.breadList.push({
          path: item.path,
          title: item.meta.title,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/common.scss";
@import "../style/navabr.scss";

.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.modal-enter-to,
.modal-leave {
  opacity: 1;
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.5s ease;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* 使用视口宽度来占满屏幕 */
  height: 100vh;
  /* 使用视口高度来占满屏幕 */
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明遮罩背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transform: translateY(0) !important;
}

.modal-content {
  padding: 20px;
  width: 100%;
  /* 设置弹框的宽度 */
  height: 100%;
  /* 设置弹框的高度 */
  background-color: white;
  background-image: url(../assets/image/201731143751771.png);
  overflow: auto;

  /* 添加滚动条以处理溢出内容 */
  .mcontent {
    display: flex;
    justify-content: space-between;
    padding: 17rem 22.625rem;
    transition: all 1s ease;

    &-child {
      text-align: center;
      margin-bottom: 15px;
      color: #fff;
      cursor: pointer;
    }

    &-child:hover {
      color: #000;
    }
  }

  .icon-btn {
    width: 50px;
    height: 50px;
    border: 1px solid #cdcdcd;
    background: transparent;
    border-radius: 25px;
    overflow: hidden;
    transition: width 0.2s ease-in-out;
    font-weight: 500;
    font-family: inherit;
    position: absolute;
    right: 51px;
    top: 51px;
  }

  .add-btn:hover {
    width: 120px;
  }

  .add-btn::before,
  .add-btn::after {
    transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    content: "";
    position: absolute;
    height: 4px;
    width: 10px;
    top: calc(50% - 2px);
  }

  .add-btn::after {
    right: 14px;
    overflow: hidden;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  .add-btn::before {
    left: 14px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .icon-btn:focus {
    outline: none;
  }

  .btn-txt {
    opacity: 0;
    transition: opacity 0.2s;
    color: #fff;
  }

  .add-btn:hover::before,
  .add-btn:hover::after {
    width: 4px;
    border-radius: 2px;
  }

  .add-btn:hover .btn-txt {
    opacity: 1;
  }

  .add-icon::after,
  .add-icon::before {
    transition: all 0.2s ease-in-out;
    content: "";
    position: absolute;
    height: 22px;
    width: 3px;
    top: calc(50% - 10px);
    background: rgb(255, 255, 255);
    overflow: hidden;
  }

  .add-icon::before {
    left: 22px;
    transform: rotate(45deg);
    /* 将图标旋转为 x 型 */
    border-radius: 2px;
  }

  .add-icon::after {
    right: 22px;
    transform: rotate(-45deg);
    /* 将图标旋转为 x 型 */
    border-radius: 2px;
  }

  .add-btn:hover .add-icon::before {
    left: 15px;
    height: 4px;
    top: calc(50% - 2px);
  }

  .add-btn:hover .add-icon::after {
    right: 15px;
    height: 4px;
    top: calc(50% - 2px);
  }
}

.slide-enter,
.slide-leave-to {
  transform: scale(1);
}

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: $navbarBgc !important;

  .el-menu-demo {
    height: 6.25rem;
    background: none;
  }

  ::v-deep .el-menu-item {
    color: $navbarColor !important;
    transition: none;
    margin-right: 1px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: $navbarHoverBgc !important;
      color: #c09b30 !important;
      border-bottom: 3px solid #c09b30 !important;
    }
  }

  .is-active {
    color: #c09b30 !important;
  }

  &-content {
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    color: #fff;
    font-size: 1rem;
    height: 3.75rem;
    background-color: rgba(166, 136, 73, 0.5);
  }
}

.hoverEN:hover {
  ::v-deep .el-image__inner {
    content: url("../assets/image/EN-white.png");
  }
}

.hoverSearch:hover {
  ::v-deep .el-image__inner {
    content: url("../assets/image/搜索 (3).png");
  }
}

.hoverList:hover {
  ::v-deep .el-image__inner {
    content: url("../assets/image/列表.png");
  }
}

.navbar-content {
  position: absolute;
  top: 6.25rem;
  height: 4rem;
  visibility: hidden;
  min-width: 118.9375rem;

  ul {
    display: flex;
  }

  .navbar-content-item {
    margin-right: 3.125rem;
    cursor: pointer;

    &:hover {
      color: #c09b30;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.navbar-content-show {
  visibility: visible;
}

.navbar-content-test {
  position: absolute;
  background-color: rgba(166, 136, 73, 0.5);
  top: 100px;
  left: 0;
  right: 0;
  height: 4rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  cursor: default;

  ul {
    display: flex;
    transform: translateX(-50%);
  }

  div {
    display: flex;
  }

  span {
    cursor: pointer;
  }

  span:hover {
    color: #c09b30;
  }

  .navbar-content-item-test {
    margin: 0 1.5625rem;
  }
}

.navbar {
  min-width: 118.9375rem;

  .el-menu {
    position: relative;

    .sanjiao {
      position: absolute;
      bottom: 5px;
      left: 45%;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #b29550;
      /* 三角形的底边 */
    }

    .navbarCurrent {
      color: #c09b30 !important;
    }
  }
}

.bread {
  padding: 8.125rem 22.5rem 3.125rem;
  background-image: url("../assets/image/bg46.png");

  .breadcrumb {
    margin-top: 2rem;
    height: 1rem;
    font-family: SourceHanSansCN-Regular;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    min-width: 15rem;
  }

  ::v-deep .el-breadcrumb__inner a:hover,
  ::v-deep .el-breadcrumb__inner.is-link:hover {
    color: #ba9c45;
  }
}

::v-deep .el-menu.el-menu--horizontal {
  height: 6.25rem;
}

::v-deep .el-menu--horizontal > .el-menu-item {
  height: 6.2rem;
  line-height: 6.25rem;
  font-size: 1.0625rem;
  padding: 0 3.125rem;
  position: static;
  float: none;
  display: flex;
  justify-content: center;
  &:hover {
    &::before {
      content: "";
      display: flex;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid transparent;
      border-bottom: 10px solid #b29550;
      position: absolute;
      bottom: 3px;
    }
  }
}

.navbar {
  .el-menu {
    display: flex;
    position: static;
  }
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 3.125rem;
  line-height: 3.125rem;
}

::v-deep .el-menu.el-menu--horizontal {
  border: none;
}

.navbars {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.9375rem;
  background-color: rgb(255, 255, 255);
  height: 3.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .logos {
    display: flex;
    cursor: pointer;
  }

  .toolss {
    margin: 0;

    .mbuttons {
      border: none;
      border-radius: 0;

      ::v-deep .el-image__inner {
        width: 1.875rem;
        height: 1.5625rem;
      }

      &::before {
        display: none;
      }

      &:active,
      &:hover {
        scale: 1;
        box-shadow: none;
        background: none;
      }

      &:hover::before {
        scale: 1;
      }
    }
  }
}
::v-deep .el-menu-item.is-active {
  border-bottom: 1px solid $navbarBottomBorder !important;
}
</style>
