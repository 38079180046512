<template>
  <div class="navbars">
    <div class="logos">
      <el-image v-if="$route.path === '/h5'" :src="require('@/assets/image/h5/h5logow.png')"></el-image>
      <el-image v-else style="width: 250px; height: 71px" :src="require('@/assets/image/h5/h5logob.png')"></el-image>
    </div>
    <div class="toolss">
      <button class="mbuttons" @click="showModal">
        <el-image v-if="$route.path === '/h5'" :src="require('../assets/image/h5/h5mw.png')"></el-image>
        <el-image v-else :src="require('../assets/image/h5/h5mw.png')"></el-image>
      </button>
    </div>
    <NavbarDrawer v-if="drawer" @drawerController="onCloseDrawer" :drawer="drawer" :navlist="navlist"></NavbarDrawer>
  </div>
</template>

<script>
import { getnavlist } from '@/api/article';
import NavbarDrawer from '../components/NavbarDrawer.vue';
export default {
  name: 'NavBarH5',
  components: {
    NavbarDrawer,
  },
  data() {
    return {
      drawer: false,
      navlist: [], //总览列表
    };
  },
  created() {
    getnavlist().then((res) => {
      this.navlist = res.data.data;
    });
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    showModal() {
      this.drawer = true;
    },
    onCloseDrawer(data) {
      this.drawer = data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style/common.scss';
@import '../style/navabr.scss';

.navbars {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.875rem;
  height: 3.75rem;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .logos {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-image {
        height: 2.3125rem;
        width: 10.0625rem;
    }
  }

  .toolss {
    margin: 0;

    .mbuttons {
      border: none;
      border-radius: 0;

      ::v-deep .el-image__inner {
        width: 1.875rem;
        height: 1.5625rem;
      }

      &::before {
        display: none;
      }

      &:active,
      &:hover {
        scale: 1;
        box-shadow: none;
        background: none;
      }

      &:hover::before {
        scale: 1;
      }
    }
  }
}
</style>
