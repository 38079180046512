// 修改navbar导航栏样式
export const changeNavbar = (path) => {
  const bodyStyle = document.getElementsByTagName('body')[0].style;
  if (path === '') {
    bodyStyle.setProperty('--navbarBgc', 'rgba(0,0,0,0.5)');
    bodyStyle.setProperty('--navbarHoverBgc', 'rgba(0,0,0,0.001)');
    bodyStyle.setProperty('--navbarColor', '#fff');
    bodyStyle.setProperty('--navbarBottomBorder', 'none');
    bodyStyle.setProperty('--mbuttonBorder', '2px solid rgb(193, 163, 98)');
    bodyStyle.setProperty('--logo', "url('../assets/image/logo.png')");
  } else {
    bodyStyle.setProperty('--navbarBgc', '#fff');
    bodyStyle.setProperty('--navbarHoverBgc', '#fff');
    bodyStyle.setProperty('--navbarColor', '#2c2c2c');
    bodyStyle.setProperty('--navbarBottomBorder', '1px solid #d8d8d8');
    bodyStyle.setProperty('--mbuttonBorder', '2px solid rgb(199, 199, 199)');
    bodyStyle.setProperty('--logo', "url('../assets/image/logo2.png')");
  }
};
