// 锚点跳转
export const onGoAnchor = (name, hash, router) => {
    // 跳转锚点前先清空hash
    window.location.hash = ""
    router.push({
        name: name,
        hash: hash,
    })
}

// 页面首次加载判断是否需要锚点跳转(其他页面跳转到新的页面需要锚点跳转)
export const judgeAnchor = () => {
    if (window.location.hash) {
        if (document.querySelector(window.location.hash)) {
            document.querySelector(window.location.hash).scrollIntoView({behavior: "smooth"})
        }
    }
}

// 路由局部前置守卫来判断路由是否发生改变，如果改变就需要页面滑动到锚点位置
export const beforeRouteUpdateAnchor = (to, from, next) => {
    if (to.hash) {
        let targetElement
        if (document.querySelector(to.hash)) {
            targetElement = document.querySelector(to.hash);
        }
        if (targetElement) {
            targetElement.scrollIntoView({behavior: "smooth"});
        }
    }
    next()
}
