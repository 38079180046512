import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentNav: '', // 当前所在navbar
    isLoading: true, // 是否显示加载动画
  },
  getters: {},
  mutations: {
    updateCurrentNav(state, nav) {
      state.currentNav = nav;
    },
    changeLoadingState(state, value) {
      state.isLoading = value;
    },
    timerLoading(state, value) {
      setTimeout(() => {
        state.isLoading = value;
      }, 200);
    },
  },
  actions: {},
  modules: {},
});
