<template>
  <div :class="containerClass">
    <transition name="el-fade-in-linear">
      <div class="sidebar-item" @click="backTop" v-show="isShow">
        <img src="@/assets/image/Gotop.png" alt="" />
      </div>
    </transition>
    <el-popover v-if="isLargeViewport" class="sidebar-item" placement="left-start" trigger="hover" visible-arrow>
      <template slot="reference">
        <div class="centered-content">
          <img src="@/assets/image/Chat-Light.png" alt="" />
          <div class="center-placeholder"></div>
        </div>
      </template>
      <div class="flex items-center justify-center">
        <img class="flex justify-center items-center w-28 h-28" src="@/assets/image/qr.jpg" alt="" />
      </div>
    </el-popover>
    <el-popover v-if="isLargeViewport" class="sidebar-item" placement="left-start" width="150" trigger="hover" visible-arrow>
      <p class="text-center text-base sidebar-item-text">0991-3655877</p>
      <img src="@/assets/image/Calling-Light.png" alt="" slot="reference" />
    </el-popover>
    <div class="sidebar-item" @click="refreshPageHandler">
      <img src="@/assets/image/renovate.png" alt="" />
    </div>
  </div>
</template>

<script>
import { onResize } from '@/tools/viewport';
export default {
  name: "SidebarView",
  data() {
    return {
      isLargeViewport: true, // 默认为大视口
      isShow: false, //提示框是显示
    };
  },
  computed: {
    containerClass() {
      return this.isLargeViewport ? "sidebar" : "sidebars";
    },
  },
  created() {
    //添加滚动监听事件
    //在窗口滚动时调用监听窗口滚动方法
    window.addEventListener("scroll", this.windowScrollListener);
  },

  methods: {
    handleResize() {
      this.isLargeViewport = onResize(window.innerWidth, document.documentElement.clientWidth)
    },
    //返回顶部
    backTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },

    refreshPageHandler() {
      window.location.reload(); // 第一种方式
    },

    //监听窗口滚动
    windowScrollListener() {
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 500) {
        this.isShow = true; //大于480时显示元素
      }
      if (scrollTop < 500) {
        this.isShow = false; //小于480时隐藏元素
      }
    },
  },
  mounted() {
    this.isLargeViewport = onResize(window.innerWidth, document.documentElement.clientWidth) // 根据初始视口宽度设置 isLargeViewport
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    //离开页面时删除该监听
    window.removeEventListener("scroll", this.windowScrollListener);
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  top: 45%;
  right: 1.5rem;
  z-index: 100;

  &-item {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 5px;
    border-radius: 50%;
    background-color: #ba9c45;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0px solid #fff;
    cursor: pointer;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .text-hover {
    position: relative;
  }
  .img-hover {
    position: absolute;
    top: 0;
    right: 105%;
    width: 8.375rem;
    height: 8.375rem;
  }
}
.sidebars {
  position: fixed;
  top: 80%;
  right: 1.5rem;
  z-index: 100;
  box-shadow: x-shadow 0 5px 5px rgba(0, 0, 0, 0.5);

  &-item {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 5px;
    border-radius: 50%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    background-color: #ba9c45;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0px solid #fff;
    cursor: pointer;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .text-hover {
    position: relative;
  }
  .img-hover {
    position: absolute;
    top: 0;
    right: 105%;
    width: 8.375rem;
    height: 8.375rem;
  }
}
::v-deep el-popover-6213 {
  font-size: 16px;
}
</style>
