import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BaiduMap from 'vue-baidu-map'
import 'animate.css'; // 在 main.js 或组件中引入
import "./assets/css/input.css";
import { WOW } from "wowjs"
import "wowjs/css/libs/animate.css"
// import i18n from "./locale"
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })

Vue.use(BaiduMap, {
  /* 在这里添加您的百度地图密钥 */
  ak: 'eP4vUz6Q2oHyq0XHDD9o3vLSUjAaEGSy'
})
Vue.use(ElementUI);
Vue.prototype.$wow = new WOW({
  boxClass: "wow", // 需要执行动画的元素的class类名
  animateClass: "animated", // 动画css类，默认animate
  offset: 0, // 元素距离可视距离多少开始执行动画
  mobile: true, // 是否在移动设备上执行动画
  live: true, // 异步加载的内容是否有效
})
Vue.config.productionTip = false;
new Vue({
  store,
  router,
  // i18n,
  render: (h) => h(App),
}).$mount("#app");
