<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
</style>
