<template>
  <div class="layout">
    <NavBar v-if="$route.fullPath !== '/' && $route.fullPath !== '/h5'" />
    <NavBarH5 v-if="$route.fullPath === '/h5'"></NavBarH5>
    <router-view />
    <SidebarView></SidebarView>
    <BottomInfo v-if="$route.fullPath !== '/' && $route.fullPath !== '/h5'" />
    <FooterH5 v-if="$route.fullPath === '/h5'"></FooterH5>
  </div>
</template>

<script>
import SidebarView from "./SidebarView.vue";
import NavBar from "../components/NavBar.vue";
import BottomInfo from "@/components/BottomInfo.vue";
import NavBarH5 from "../components/NavBarH5.vue";
import FooterH5 from "@/components/FooterH5";
import { onResize } from "@/tools/viewport"
export default {
  name: "Lay-out",
  components: {
    NavBar,
    BottomInfo,
    NavBarH5,
    FooterH5,
    SidebarView,
  },

  data() {
    return {
      isLoading: true,
      isHomeH5: false, // 当前是否是首页的H5布局
      isLargeViewport: true,
    };
  },
  created() { },
  methods: {
    getWindowInfo() {
      this.isLargeViewport = onResize(window.innerWidth, document.documentElement.clientWidth)
      if (!this.isLargeViewport) {
        this.isHomeH5 = true;
        if (this.$route.path === "/") {
          this.$router.push("/h5");
        }
      } else {
        this.isHomeH5 = false;
        if (this.$route.path === "/h5") {
          this.$router.push("/");
        }
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.getWindowInfo);
    if (this.$route.fullPath === "/" || this.$route.fullPath === "/h5") {
      if (!onResize(window.innerWidth, document.documentElement.clientWidth)) {
        this.isHomeH5 = true;
      } else {
        this.isHomeH5 = false;
      }
    }
  },
};
</script>

<style scoped></style>
