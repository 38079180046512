<template>
  <div class="navbar-list">
    <el-drawer :visible.sync="drawerChild" :before-close="onCloseDrawer" direction="rtl" size="100%" :modal="false">
      <div class="sidebar">
        <el-menu class="el-menu-vertical" background-color="#b29550" text-color="#fff" active-text-color="#fff">
          <el-submenu v-for="(item, key) in navlist" :index="key.toString()" :key="key">
            <template slot="title">
              <span>{{ item.attributes.title }}</span>
            </template>
            <el-menu-item v-for="(i, index) in item.attributes.list" :index="index.toString()" :key="index" @click="onGoAnchorKnow(i, index)">
              {{ i.title }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { onGoAnchor } from "../tools/anchor";
export default {
  name: "NavbarDrawer",
  components: {},
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    navlist: {
      type: Array,
    },
  },
  data() {
    return {
      drawerChild: false,
      currentNav: "", // 当前鼠标所在的菜单
    };
  },
  created() {},
  mounted() {
    this.drawerChild = this.drawer;
    this.currentNav = this.$route.path.slice(1, this.$route.path.length);
    if (this.$route.path.slice(1, this.$route.path.length).indexOf("/") !== -1) {
      this.currentNav = this.$route.path.slice(1, this.$route.path.slice(1, this.$route.path.length).indexOf("/") + 1);
    }
  },
  computed: {},
  watch: {},
  methods: {
    onCloseDrawer() {
      this.drawerChild = false;
      this.$emit("drawerController", this.drawerChild);
    },
    onChangeMenu(data) {
      this.currentNav = data.route;
    },
    onGoAnchorKnow(data) {
      this.onCloseDrawer();
      this.currentNav = data.route;
      onGoAnchor(data.name, data.anchor, this.$router);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-submenu {
  border-bottom: 1px solid #fff;
}
::v-deep .el-submenu__title{
  padding: 0;
  padding: 0 20px;
}

::v-deep .el-drawer.rtl {
  background-color: #b29550;
}

::v-deep .el-drawer__header {
  color: #ffffff;
}

::v-deep .el-divider--horizontal {
  margin: 0;
}

::v-deep .el-submenu__title i {
  color: #ffffff;
}

::v-deep .el-menu {
  border: none;
}
</style>
