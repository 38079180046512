import axios from "axios";

// const baseURL = import.meta.env.VUE_APP_BASE_API
// 创建新的axios实例
const instance = axios.create({
  baseURL: 'https://server.cqysbwg.com/api/',
  // baseURL: 'http://192.168.3.225:1337/api/',
  // baseURL,
  timeout: 1000 * 30,
  withCredentials: true,
  headers: { "content-type": "application/json; charset=utf-8" },
});
export default instance;
