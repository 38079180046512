import axios from "@/tools/axios";
// 导航栏
export function getnavbar() {
  return axios({
    url: "/navbars?pagination[pageSize]=10",
    method: "get",
  });
}
// 轮播图
export function getswiper() {
  return axios({
    url: "/get-swiper-urls?populate=*",
    method: "get",
  });
}
// 开放时间
export function opentime() {
  return axios({
    url: "/open-dates",
    method: "get",
  });
}
// 资讯
export function getinformations() {
  return axios({
    url: "/informations",
    method: "get",
  });
}
// 第二页展览
export function getexhibitions() {
  return axios({
    url: "/exhibitonss?populate=*",
    method: "get",
  });
}
// 手风琴
export function getaccordions() {
  return axios({
    url: "/accordions?populate=*",
    method: "get",
  });
}
// 藏品信息
export function gettreasures() {
  return axios({
    url: "/treasures",
    method: "get",
  });
}
// 第五页轮播图
export function getsw() {
  return axios({
    url: "/treasure-swipers?populate=*",
    method: "get",
  });
}
// 第六页消息
export function getdatas() {
  return axios({
    url: "/datas?populate=*",
    method: "get",
  });
}
// 总览
export function getnavlist() {
  return axios({
    url: "navbar-lists?pagination[pageSize]=10",
    method: "get",
  });
}

