import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../components/Layout.vue";
import { changeNavbar } from "@/style";
import store from "@/store/index";
Vue.use(VueRouter);

const routes = [
  // H5
  {
    path: "/h5",
    component: () => import("@/components/Layout.vue"),
    meta: {
      isShowBread: true,
      title: "首页",
      type: "h5",
    },
    children: [
      {
        path: "/h5",
        name: "首页",
        meta: {
          isShowBread: true,
          title: "首页",
          type: "h5",
        },
        component: () => import("@/views/HomeH5.vue"),
      },
    ],
  },
  // PC
  {
    path: "/",
    component: Layout,
    meta: {
      isShowBread: false,
      title: "首页",
    },
    children: [
      {
        path: "/",
        name: "首页",
        component: () => import("@/views/HomeView.vue"),
        meta: {
          isShowBread: false,
          title: "首页",
        },
      },
      {
        path: "guide",
        name: "导览",
        component: () => import("@/views/guide/guide.vue"),
        meta: {
          isShowBread: false,
        },
      },
      {
        path: "cultural-create",
        name: "文创",
        component: () => import("@/views/cultural-create/cultural-create.vue"),
        meta: {
          isShowBread: false,
        },
      },
      {
        path: "cultural/:ids",
        name: "文物列表",
        component: () => import("@/views/cultural/CulturalList.vue"),
        meta: {
          isShowBread: true,
          title: "藏品",
        },
        props: true,
      },
      {
        path: "culturaldesc/:id",
        name: "文物详情",
        component: () => import("@/views/cultural/CulturalDesc.vue"),
        meta: {
          isShowBread: true,
          title: "藏品",
        },
        props: true,
      },
      {
        path: "exhibition",
        name: "展览",
        component: () => import("@/views/exhibition/exhibition.vue"),
        meta: {
          isShowBread: false,
        },
      },
      {
        path: "exhibitiondetail/:id",
        name: "展览详情",
        component: () =>
          import("@/views/exhibition-detail/exhibition-detail.vue"),
        meta: {
          isShowBread: false,
        },
        props: true,
      },
      {
        path: "news",
        name: "新闻列表",
        component: () => import("@/views/news/NewsList.vue"),
        meta: {
          isShowBread: false,
        },
        redirect: "news/one",
        children: [
          {
            path: "one",
            name: "新闻1",
            component: () => import("@/views/news/News1.vue"),
            meta: {
              isShowBread: false,
              title: "通知公告",
            },
          },
          {
            path: "two",
            name: "新闻2",
            component: () => import("@/views/news/News2.vue"),
            meta: {
              isShowBread: false,
              title: "文物咨询",
            },
          },
          {
            path: "expert",
            name: "专家列表",
            component: () => import("@/views/news/expert-list.vue"),
            meta: {
              isShowBread: false,
              title: "专家列表",
            },
          },
        ],
      },
      {
        path: "expertdesc/:id",
        name: "ExpertDesc",
        component: () => import("@/views/news/expert-desc.vue"),
        meta: {
          isShowBread: false,
          title: "专家详情",
        },
        props: true,
      },
      {
        path: "newShow/:id",
        name: "newShow",
        component: () => import("@/components/News1Itemshow.vue"),
        meta: {
          isShowBread: false,
        },
        props: true,
      },
      {
        path: "about",
        name: "About",
        component: () => import("@/views/about/about.vue"),
        meta: {
          isShowBread: false,
        },
      },
      {
        path: "leavemsg",
        name: "leaveMessage",
        component: () => import("@/components/leavemsg.vue"),
        meta: {
          isShowBread: false,
        },
      },
      {
        path: "expert",
        name: "专家列表",
        component: () => import("@/views/news/expert-list.vue"),
        meta: {
          isShowBread: false,
        },
      },
      {
        path: "volunteer",
        name: "志愿服务",
        component: () => import("@/views/education/education-volunteer.vue"),
        meta: {
          isShowBread: false,
        },
      },
      {
        name: "cloud",
        path: "/cloud",
        component: () => import("@/views/cloud/cloud-pc.vue"),
      },
    ],
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("changeLoadingState", true);
  window.scrollTo(0, 0);
  if (to.fullPath === "/" || to.fullPath === "/h5") {
    changeNavbar("");
  } else {
    changeNavbar("/");
  }
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  document.title = "春秋艺术博物馆";
  next();
});

// 解决路由重复跳转报错
// 在重写push|replace方法时，先把VueRouter原型对象的push|replace保存一份
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
// 参数1：告诉原来的push | replace方法，你往那里跳转（传递那些参数）、参数2：成功回调、 参数3：失败回调
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

export default router;
